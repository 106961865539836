<template>
  <div id="btc_tracker">
    <div class="overview">
      <div class="top" v-if="current_market">
        <div class="main-price">
          <div class="icon btc s48"></div>
          <div class="text">
            <label>Precios Bitcoin en MXN</label>
            <div class="numbers">
              <div class="currency">$</div>
              <div class="price">{{ format_number(current_market.buy_price) }}</div>

              <div :class="'percent '+(current_market.percent < 0 ? 'red' : 'green')" v-if="current_market.percent">
                ({{ format_percent(current_market.percent) }}%)<div :class="'mini-icon s24 '+(current_market.percent < 0 ? 'down' : 'up')"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="market-selector">
          <label>Basado en</label>

          <select v-model="selected_market">
            <option value="global">Todos los mercados</option>
            <option value="bitso">Bitso</option>
            <option value="localbitcoins">LocalBitcoins</option>
            <option value="coinrex">Coinrex</option>
            <option value="domitai">Domitai</option>
          </select>
        </div>
      </div>

      <div class="calculator">
        <div class="input btc">
          <input @input="calculate_exchange('btc')" type="text" v-model="calculator.btc" placeholder="Bitcoin" />
          <label>BTC</label>
        </div>

        <div class="icon s24 exchange"></div>

        <div class="input">
          <input @input="calculate_exchange('pesos')" type="text" v-model="calculator.pesos" placeholder="MXN" />
          <label>Pesos Mexicanos</label>
        </div>
      </div>

      <div class="chart">
        <ul class="periods">
          <li class="period-year" :class="{active: period == 'year'}" @click="period = 'year'">Año</li>
          <li class="period-month" :class="{active: period == 'month'}" @click="period = 'month'">Mes</li>
          <li class="period-day" :class="{active: period == 'day'}" @click="period = 'day'">Día</li>
        </ul>
        <canvas id="btc_chart" ref="chart"></canvas>
      </div>

    </div>

    <div class="tracker-section" v-if="current_market">
      <div class="head">
        <div class="mini-icon chart s32"></div> Mercados
      </div>

      <table>
        <thead>
          <td style="width: 27%">Mercado</td>
          <td style="width: 23%">Volumen BTC (24h)</td>
          <td>Volumen %</td>
          <td>Precio Compra</td>
          <td class="td-last-update">Actualizado</td>
        </thead>
        <tbody>
          <tr :class="{active: selected_market == market.market}" @click="selected_market = market.market" v-for="market in real_markets" :key="market.market">
            <td><div :class="'mini-icon s16 '+market.market"></div> <b>{{ market_names[market.market] }}</b></td>
            <td>{{ format_number(market.volume_24) }}</td>
            <td>{{ market_percent(market).toFixed(1) }}%</td>
            <td>${{ format_number(market.buy_price) }}</td>
            <td class="td-last-update"><span class="gray">Último minuto</span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
const axios = require('axios')
const _ = require('lodash')
const moment = require('moment')

import { Chart } from 'chart.js'
require('chartjs-plugin-crosshair')

// const ElementQueries = require('css-element-queries/src/ElementQueries');

export default {
  data(){
    return {
      markets: [],
      selected_market: 'global',
      graph_data: [],
      period: 'day',

      market_names: {
        localbitcoins: "LocalBitcoins",
        coinrex: "Coinrex",
        bitso: "Bitso",
        domitai: "Domitai"
      },

      calculator: {
        btc: 1,
        pesos: 0
      }
    }
  },

  mounted(){
    this.chart = new Chart(this.$refs.chart, {type: 'line', options: {
      animation: false,
      maintainAspectRatio: false,
      responsive: true,

      tooltips: {
        mode: 'index',
        intersect: false
      },
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true
        }
      },
      scales: {
        yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: false
            }
        }],
        xAxes : [ {
            gridLines : {
                display : false
            }
        } ]
      },
      plugins: {
        crosshair: {
          line: {
            color: 'rgba(0, 0, 0, .35)',  // crosshair line color
            width: 1        // crosshair line width
          },
          zoom: {
            enabled: false,   
          }
        }
      }
    }});

    // ElementQueries.init();
  },

  created(){
    this.fetch_markets()

    setInterval(this.fetch_markets, 1000*60)
    setInterval(this.fetch_graph, 1000*60)
  },

  methods: {
    market_percent(market){
      let total_volume = _.sum(_.map(this.real_markets, 'volume_24'))
      return (market.volume_24 / total_volume) * 100
    },

    format_number(n){
      return n ? (Math.round(n * 100) / 100).toLocaleString('en', { minimumFractionDigits: 2 }) : 0
    },

    format_percent(n){
      n = n ? (Math.round(n * 100) / 100).toLocaleString('en', { minimumFractionDigits: 2 }) : 0

      if(n == 0 || (n && n >= 0)){
        n = `+${n}`
      }

      return n
    },

    fetch_markets(){
      axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/markets`).then((rsp) => {
        rsp = rsp.data

        if(rsp.status && rsp.status.code == 200){
          this.$set(this, 'markets', rsp.data)
        }
      })
    },

    fetch_graph(){
      axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/markets/${this.selected_market}`, {
        params: { period: this.period }
      }).then((rsp) => {
        rsp = rsp.data

        if(rsp.status && rsp.status.code == 200){
          this.$set(this, 'graph_data', rsp.data)
        }
      })
    },

    calculate_exchange(from='btc'){
      if(from == 'btc'){
        this.calculator.pesos = _.round((this.current_market.buy_price * +this.calculator.btc) || 0, 2)
      }else{
        this.calculator.btc = _.round((+this.calculator.pesos / this.current_market.buy_price) || 0, 5)
      }
    }
  },

  computed: {
    '_' : () => {
      return _
    },

    current_market(){
      return _.find(this.markets, {market: this.selected_market})
    },

    real_markets(){
      return _.sortBy(_.filter(this.markets, (m) => { return m.market != 'global' }), 'volume_24').reverse()
    }
  },

  watch: {
    selected_market: {
      immediate: true,
      handler(){
        this.fetch_graph()
      }
    },

    current_market(){
      this.calculate_exchange()
    },

    period(){
      this.fetch_graph()
    },

    graph_data(){
      let time_labels = _.map(_.map(this.graph_data, 'time'), (t) => {
        if(this.period == 'day'){
          return moment(t).format('LT')
        }else if(this.period == 'month'){
          return moment(t).format('MMM DD')
        }else{
          return moment(t).format('MMM DD')
          // return moment(t).format('MM/DD/YYYY')
        }
      })

      this.chart.data = {
        labels: time_labels,
        datasets: [
          {
            label: 'Precio Compra',
            data: _.map(_.map(this.graph_data, 'buy_price'), (n) => { return Math.round(n*100) / 100 }),
            backgroundColor: '#ff4444',
            borderColor: '#ff4444',
            pointRadius: 1,
            fill: false
          },
          {
            label: 'Volumen BTC (24h)',
            data: _.map(_.map(this.graph_data, 'volume_24'), (n) => { return Math.round(n*1000) / 1000 }),
            backgroundColor: '#93c899',
            borderColor: '#93c899',
            pointRadius: 0,
            borderWidth: 1,
            fill: false
          }
        ]
      }

      this.chart.options.scales.yAxes[0].ticks.min = Math.round(_.min(_.map(this.graph_data, 'buy_price')) * 0.95)

      this.chart.update()
    }
  }
}
</script>
 
<style lang="scss">
  @import "assets/css/app.scss";
</style>
